import React, { useEffect, useState } from 'react'
import { Button, Icon, Box } from '@rebeldotcom/components'
import { hasDOM } from '@rebeldotcom/ui'

const ScrollTopWidget = () => {
  const [shouldShow, setShouldShow] = useState(false)

  const scrollTop = () => {
    if (hasDOM) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const scrollChecker = () => {
    const y = window.scrollY

    if (y > 200) {
      setShouldShow(true)
    } else {
      setShouldShow(false)
    }
  }

  useEffect(() => {
    if (hasDOM) {
      window.addEventListener('scroll', scrollChecker)
    }

    return () => {
      if (hasDOM) {
        window.removeEventListener('scroll', scrollChecker)
      }
    }
  })

  return (
    <Box
      bottom={['20rem', '8rem', '8rem']}
      id="scroll-top-container"
      position="fixed"
      right={['2rem', '7.5rem', '7.5rem']}
      zIndex="99"
    >
      {shouldShow && (
        <Button
          ariaLabel="Scroll to top of page"
          height="3.5rem"
          id="scrollTopBtn"
          onClick={scrollTop}
          type="button"
          variant="scroll-widget"
          width="4.8rem"
        >
          <Icon
            color="white"
            name="chevron-up"
            title="scroll to top icon"
            titleId="scrollToTopIcon"
          />
        </Button>
      )}
    </Box>
  )
}

export default ScrollTopWidget
