/* eslint-disable react/boolean-prop-naming */
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Box, redesignTheme } from '@rebeldotcom/components'
import {
  CoreProvider,
  Header,
  Footer,
  SessionProvider,
  UserProvider,
  isInternic,
  SunshineConversations,
} from '@rebeldotcom/ui'
import ScrollTopWidget from '../scroll-top'
import { Feedback } from '../feedback'

const Layout = ({
  children,
  hasNavigation,
  hasCheckOutNav,
  location,
  showNewsletterSignup,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <CoreProvider location={location} theme={isInternic ? {} : redesignTheme}>
      <>
        <Helmet
          meta={[{ name: 'description', content: 'Rebel Home' }]}
          title={data.site.siteMetadata.title}
        >
          <html lang="en" />
          <script
            defer
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            type="text/javascript"
          />
        </Helmet>

        <Feedback />

        <SessionProvider>
          <UserProvider>
            <Header
              hasCheckOutNav={hasCheckOutNav}
              order={{}}
              removeNav={!hasNavigation}
            />

            <Box
              as="main"
              flexDirection="column"
              id="main-container"
              mt={!hasCheckOutNav ? '8rem' : 0}
            >
              <ScrollTopWidget />
              <SunshineConversations />
              {children}
            </Box>
            {hasNavigation && (
              <Footer showNewsletterSignup={showNewsletterSignup} />
            )}
          </UserProvider>
        </SessionProvider>
      </>
    </CoreProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasCheckOutNav: PropTypes.bool,
  hasNavigation: PropTypes.bool,
  location: PropTypes.string.isRequired,
  showNewsletterSignup: PropTypes.bool,
  theme: PropTypes.shape({}),
}

Layout.defaultProps = {
  hasCheckOutNav: false,
  hasNavigation: true,
  showNewsletterSignup: true,
  theme: undefined,
}

export default Layout
