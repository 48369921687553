import React from 'react'
import { Helmet } from 'react-helmet'
import { hasDOM } from '@rebeldotcom/ui'

const projectsPages = {
  '/my-account/': 'ksnibtx3jou4kyzwzsilfa',
}

const getFeedbackProjectId = () => {
  const { pathname: page } = window.location
  if (projectsPages[page.toLowerCase()]) {
    return projectsPages[page.toLowerCase()]
  }
  return null
}

const showFeedback = () => {
  if (__PROD__ || __TEST__) return false
  if (hasDOM) {
    const { pathname: page } = window.location
    if (projectsPages[page.toLowerCase()]) {
      return true
    }
  }
  return false
}

// eslint-disable-next-line react/prop-types
const Bugherd = ({ projectId }) => {
  console.log(projectId)
  return (
    <Helmet>
      <script
        async="true"
        src={`https://www.bugherd.com/sidebarv2.js?apikey=${projectId}`}
        type="text/javascript"
      />
    </Helmet>
  )
}

const Feedback = () => {
  if (!showFeedback()) return null
  const projectId = getFeedbackProjectId()
  return <Bugherd projectId={projectId} />
}

export { Feedback }
